import React from "react"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import Layout from "../components/layout"
import SEO from "../components/seo"

import SarahLeir from "../images/SarahLeir.jpg"
import Gabby from "../images/gabby.jpg"
import Alex from "../images/alex.jpg"
import Katey from "../images/katey.jpg"
import Shelley from "../images/shelley.jpg"
import Christa from "../images/christa.jpg"
import Meaghan from "../images/meaghan.jpg"
import Machi from "../images/machi.jpg"

import Shaun from "../images/Shaun.jpg"
import Lorna from "../images/Lorna.png"
import Paul from "../images/paul.jpg"
import Lisa from "../images/lisa.jpg"
import Mary from "../images/mary.jpg"
import Ryan from "../images/ryan.jpg"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"

const Title = styled.div``

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 300px;
  margin-bottom: 32px;
  background-size: 300px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const PersonBox = styled(Box)`
  padding: 25px 0;
  margin-top: 20px;
  font-family: "Benton Sans";
  flex-direction: row;
  @media (max-width: 768px) {
    padding: 0;
  }
`

const DescriptionLeft = styled.div`
  vertical-align: top;
  margin-right: 30px;
  flex-grow: 1;
  font-family: "Benton Sans";
  text-align: justify;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`

const DescriptionRight = styled(DescriptionLeft)`
  margin-left: 30px;
  margin-right: 0;
`

const DesktopPhoto = styled.div`
  background-image: url("${props => props.img}");
  background-size: ${props => props.size};
  background-repeat: no-repeat;
  background-position: ${props => (props.position ? props.position : "top")};
  width: 220px;
  height: 300px;
  border-radius: 5px;
  @media (max-width: 768px) {
    display: none;
  }
`

const MobilePhoto = styled.div`
  background-image: url("${props => props.img}");
  background-size: ${props => props.size};
  background-repeat: no-repeat;
  background-position: ${props => (props.position ? props.position : "top")};
  width: 110px;
  height: 150px;
  margin: 0 auto 20px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const PersonTitle = styled.h2`
  margin-bottom: 12px;
`

const PersonLeft = props => {
  return (
    <PersonBox display="flex">
      <div>
        <DesktopPhoto
          img={props.img}
          position={props.position}
          size={props.size}
        ></DesktopPhoto>
      </div>
      <DescriptionRight>
        <PersonTitle>{props.title}</PersonTitle>
        <MobilePhoto
          img={props.img}
          position={props.position}
          size={props.size}
        ></MobilePhoto>
        <div>{props.children}</div>
      </DescriptionRight>
    </PersonBox>
  )
}

const PersonRight = props => {
  return (
    <PersonBox display="flex">
      <DescriptionLeft>
        <PersonTitle>{props.title}</PersonTitle>
        <MobilePhoto
          img={props.img}
          position={props.position}
          size={props.size}
        ></MobilePhoto>
        <div>{props.children}</div>
      </DescriptionLeft>
      <div>
        <DesktopPhoto
          img={props.img}
          position={props.position}
          size={props.size}
        ></DesktopPhoto>
      </div>
    </PersonBox>
  )
}

class WeddingPartyPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="The wedding party | Sara & Tim" />
        <Title>
          <H1>MEET THE WEDDING PARTY</H1>
          <LeafBorderPicture />
        </Title>
        <div>
          <PersonRight
            title="Sarah Leir, Maid of honour"
            img={SarahLeir}
            size="contain"
          >
            <p>
              When we first met on LSHTM induction day, I knew straight away
              that Sarah and I would be friends, kindred spirits even. Though
              taking different MScs, we both managed to get separated from our
              respective coursemates after choosing an inopportune time to take
              a loo break. We’ve been close friends ever since. From London to
              Southampton to Cambridge to Ottawa, Sarah is my number one go to,
              not only for medical advice, but for: watching period dramas while
              eating chocolate/cake, fun nights out, fancy dress inspiration,
              escape rooms, bottomless brunches, adventures travelling, camping
              and festival-ing and for help finishing a bottle of wine –
              anywhere, anytime! I couldn't ask for a more lovely maid of
              honour.
            </p>
          </PersonRight>
          <PersonLeft
            title="Paul Fawkesley, Best man"
            img={Paul}
            size="contain"
          >
            <p>
              The first time we met, I thought “what a strange looking boy”. And
              not just because he was dressed as a griffyn. By chance, we were
              later placed in the same tutor group and fell-in as
              comrades-in-arms, fighting together through the bloody battles of
              growing up - exams, puberty, class politics and girls. We survived
              them all. Now, scarred, but mostly intact, we share the deep
              connection of joint survival. Paul is an unusual, incurably
              curious, incessantly interesting human being - a veritable force
              of nature who’s the Starsky to my Hutch, the Jekyll to my Hyde. As
              my right-hand-man, I look forward to his support through all of
              the interesting and unexpected challenges that this wedding has in
              store.
            </p>
          </PersonLeft>
          <PersonRight
            title="Shelley Holmes, Bridesmaid"
            img={Shelley}
            size="cover"
          >
            <p>
              Shelley is my little sister and first ever friend. We have had our
              disagreements over the years – what to watch on TV (to this day
              Pokémon is still way better than Calliou!), what movies to rent at
              Blockbuster, who Coco the dog loved more (probably was always
              Mum), who hurt whom to get Mum/Dad’s sympathy (she definitely
              ninja-kicked me in the stomach that one time) and who was cooler
              (in retrospect, probably never me). BUT Shelley and I have grown a
              lot closer over the years. She is my go to for: epic car karaoke
              to Bette Davis Eyes and the Lion King, cookie recipes, sisterly
              bonding over shared memories and access to pudgy cuddles from my
              nephew, Austin.
            </p>
          </PersonRight>
          <PersonLeft
            title="Shaun O'Donovan, Groomsperson"
            img={Shaun}
            size="cover"
          >
            <p>
              With his rebelliously untucked shirt, hand-doodle-covered school
              books and don’t-give-a-monkey attitude, Shaun was far too wild for
              me when we first met at school. Years later, when Shaun invited
              himself along travelling with Ryan and me, that all changed. Now I
              can’t imagine not knowing this adorable little scamp. With his
              unbounded passion for history and world affairs, his aptitude for
              mischievous calamity, quenchless thirst for tea and agility in
              consuming unguarded food, Shaun always provides excellent
              entertainment value. Shaun is now one of Britain’s most valued
              exports (just behind Cheddar cheese), having moved to Bucharest
              over four years ago.
            </p>
          </PersonLeft>

          <PersonRight title="Alex Rigby, Bridesmaid" img={Alex} size="cover">
            <p>
              Alex and I met when I first moved to London after being brought
              together by fate (aka our mutual friend Michelle). Based on that
              one coffee date on Oxford Street, I knew we would be long-lasting
              friends. Alex said it best on her own wedding website description
              of us – “Always my sounding board, she is my go to when asking
              questions that start with… am I being crazy?” Also for when I
              need: decoration and style tips, help finishing a bottle of
              Prosecco or drinking cocktails, eating chocolate dipped Oreos or
              sushi, a shopping buddy, boy chat and most importantly, someone
              who can speak Italian to find out just where in Lake Como our
              rental car has been towed to.
            </p>
          </PersonRight>
          <PersonLeft
            title="Lorna Benton, Groomsperson"
            img={Lorna}
            size="cover"
          >
            <p>
              If you had to find a human personification of a battery, Lorna
              would be it. Energising any occasion with her boundless enthusiasm
              for almost everything (the more obscure or unusual, the better),
              Lorna is a constant inspiration to push boundaries and experience
              new things. My first encounters with improvised comedy, tennis,
              paddleboarding, the Turbans, Easter Olympics, vegan cheese, Jack
              Benton and South Africa all have Lorna’s fingerprints on them.
              With her moral compass in hand and heart on sleeve, she’s usually
              the one leading the charge in the right direction through the maze
              of life.
            </p>
          </PersonLeft>

          <PersonRight
            title="Meaghan Kall, Bridesmaid"
            img={Meaghan}
            size="cover"
          >
            <p>
              I met Meaghan when I started working at Public Health England.
              Though she thought I was too quiet (only at first), we soon bonded
              over running, work gossip, The Listening Room, looking weird when
              we were younger and growing up abroad. We’ve been work wives ever
              since! Meaghan is my go to for: matching American Eagle outfits,
              long tea breaks, hiking holidays, discussing RuPaul’s Drag Race,
              Stranger Things or Line of Duty, running challenges (Tough Mudder
              and 12 races in 12 months), boring run chat (this one time, I
              bought this oven…), PhD mental health support, and dancing to
              Justin Bieber. Also when I feel like I want to have a conversation
              very loudly in the office in an obnoxious North American accent.
            </p>
          </PersonRight>
          <PersonLeft
            title="Ryan Halloran, Groomsperson"
            img={Ryan}
            size="cover"
          >
            <p>
              The serendipity of life placed our homes within two minutes of
              each other so that we shared the school journey together every day
              for seven years. What started as a marriage of convenience
              gradually bloomed into a mild friendship that led to us exploring
              foreign lands together in a six-month adventure, full of discovery
              of the world and ourselves and sometimes, each other. With so much
              mutual blackmail material, we’ve had to remain close. The silver
              lining is that I’ve actually wanted to. With his affable,
              bear-like demeanor and unbounded supply of colourful metaphors,
              Ryan is an essential addition to any groom's party.
            </p>
          </PersonLeft>

          <PersonRight
            title="Christa Smolarchuk, Bridesmaid"
            img={Christa}
            size="cover"
          >
            <p>
              Christa and I go way back. We met in St. Albert in the first year
              of highschool when Christa thought I was annoying. Not a surprise…
              I was. We first became friends at university when we completed the
              same BSc programme. Christa has been following me around ever
              since – from St. Albert to London to LSHTM to PHE. Even though she
              has returned to Alberta, Christa is always my go to when I want to
              commiserate about having “bad luck”, burn some calories with Shaun
              T, discuss statistical techniques in infectious disease
              epidemiology (it’s as fun as it sounds!), go snorkelling with a
              watermelon rind (not an octopus) in Tenerife and get a sarcastic
              dose of reality.
            </p>
          </PersonRight>
          <PersonLeft
            title="Lisa Fawkesley, Groomsperson"
            img={Lisa}
            size="cover"
          >
            <p>
              When they said that the best things come in small packages, I’m
              pretty sure they’d just met Lisa. Even though she's one of the few
              people I could actually look down on, I don’t often get that
              pleasure as I’m usually too busy trying to keep up with her
              curious assault on the unknown. Lisa still retains the incurable
              inquisitiveness (and maniacal cackle) that both endeared her to me
              and drove Mr. Knight to distraction when we shared Chemistry
              together in sixth form. When she’s not working on solving the
              world’s sanitation problems or talking to absolutely everyone in
              the street, Lisa is an invaluable friend, especially when it comes
              to keeping a certain Fawkesley in line.
            </p>
          </PersonLeft>
          <PersonRight
            title="Machi 'Andrea' Iliopoulou, Bridesmaid"
            img={Machi}
            size="cover"
          >
            <p>
              I first met Machi (currently rebranding as Andrea) during our
              first week at Lillian Penson Hall, when I walked into the lobby
              and she was sitting there being gorgeous, surrounded by all the
              good-looking boys. Apparently, she thought I looked fun and we
              spent the next year partying it up with our LPH crew: exploring
              London, drinking in the Sussex Arms with all the builders,
              parading around in fancy dress and getting involved in all the
              drama. Machi has been a close and constant friend since, who has
              been here through it all; case in point - we survived Shadwell
              together. She is my go to for: coffee and lemon tart catch-ups in
              Angel, fancy dinners, horse-riding tips, insight into the
              psychology of banker-wankers and serial killers, reminiscing about
              when we were still in our 20s and Greek food in London.
            </p>
          </PersonRight>
          <PersonLeft title="Mary Dobson, Groomsperson" img={Mary} size="cover">
            <p>
              United by our joint suffering of the dilapidated first-year
              accomodation known only (and somewhat, ironically) as “Number 1”,
              it was inevitable that “Scary” Mary “Dobbo” Dobson and I would
              become pals. Having wisdom beyond her already impressive number of
              years, Dobbo has always been a crucial and much utilised source of
              counsel and insight. Rivalling Wallace for love of cheese and Amy
              Winehouse for love of.. wine, she has also been a great source of
              Port Salut and Pinot. Everyone needs a friend with Dobbo’s fierce
              and unquestioning loyalty - and luckily for me, I have one!
            </p>
          </PersonLeft>
          <PersonRight
            title="Katey Stuparyk, Bridesmaid"
            img={Katey}
            size="130%"
            // position="center"
          >
            <p>
              Katey and I met in highschool; we became friends during our
              scientific internship placements at the U of A. That summer was
              spent drinking Slurpees, eating Mr Sub/blue whales and chatting on
              the quad about Big Brother. Over the years, we’ve made some
              priceless memories: hanging out watching The O.C. and eating DQ
              blizzards, enjoying summer BBQ pool parties, camping and fleeing
              tornados (we don't talk about that time with the toilet paper!),
              clubbing in glitter and “bar shirts” and holidaying in Mexico,
              Disney World and Bowen Island. Our friendship has been long
              distance for the past decade, but Katey is, and will always be, a
              keeper!
            </p>
          </PersonRight>
          <PersonLeft
            title="Gabrielle Leonhardt, Bridesmaid"
            img={Gabby}
            position="60% 80%"
            size="170%"
          >
            <p>
              Gabby and I also met in highschool. We joke that I originally only
              asked her to my birthday party as Katey’s plus one, BUT after
              spending time together, I quickly realised how awesome she is.
              Throughout school and university, Gabby and I spent countless
              hours at the Blais house curled up in blankets and chatting over
              cups of tea, playing games with the entire Blais clan, hot-tubbing
              (and falling through the deck) and drinking whiskey sours and
              other “man” drinks. Gabby and I have been through a lot together:
              surprise birthday parties, boy drama, camping trips (with and
              without tent poles), gigs and multiple adventures in England,
              Mexico and Disney World. Despite there being an ocean between us,
              Gabby is a true friend for life.
            </p>
          </PersonLeft>
        </div>
      </Layout>
    )
  }
}

export default WeddingPartyPage
